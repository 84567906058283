@import "../../assets/sass/theme.scss";

.headerBox {
    font-family: $viet;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: start;
    background: url('../../../public/media/Rectangle\ 91.svg'), lightgray -775.682px -750.993px / 151.658% 799.478% no-repeat;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0 #{scaleXL(40)} #{scaleXL(30)} #{scaleXL(30)};

    margin: #{scaleXL(50)} 24px;
    max-width: #{scaleXL(1630)};

    @media (max-width: 500px) {
        //
    }

    .h3 {
        font-family: $viet;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}

.headerBox2 {
  font-family: $viet;
  border-radius: 10px;
  border: none;
  color: white;
  margin: 0 24px;
  max-width: #{scaleXL(1630)};

  .h3 {
      font-family: $viet;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
  }
}

.headerText {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--white, #FFF);
    font-family: $viet;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    opacity: 0.8;
}

.crumbElement {
    font-weight: 400;
}

.buttonDark {
  text-decoration: none;
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, rgba(50, 63, 220, 0.20) 0%, rgba(179, 72, 233, 0.20) 100%);
  padding: #{scaleXL(5)} 0;
  border-radius: #{scaleXL(8)};

  @include text500();
  font-size: #{scaleXL(14)};
  color: $white;
  cursor: pointer;

  &:hover {
      filter: saturate(120%) contrast(1.5) drop-shadow(2px 2px 2px #8888888a);
      color: white;
  }  
}

.buttonLight {
  text-decoration: none;
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, #323fdc 0%, #b348e9 100%);
  padding: #{scaleXL(5)} 0;
  border-radius: #{scaleXL(8)};

  @include text500();
  font-size: #{scaleXL(14)};
  color: $white;
  cursor: pointer;

  &:hover {
      filter: saturate(120%) contrast(1.2) drop-shadow(2px 2px 2px #8888888a);
      color: white;
  }      
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
  color: gray !important;
  font-weight: bold !important;
  filter: saturate(60%) contrast(1.2);
}

.shatter {
  animation: shatter 0.5s ease-out; /* Define the shatter animation */
}
  
@keyframes shatter {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) rotate(15deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
}

.moveEffect {
  position: relative;
}
  
.rewardInfo {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: gray;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 40%;
  height: 20%;
  align-items: center;
}
  
.mysteryImage {
  max-width: 100%;
  border-radius: 20px;
}

.sectionContainer {
  background: #0C0E15;
  padding: 20px;
  border-radius: 10px;
}

.numbersModal {
  border-radius: 10px;
  width: 100%;
  max-width: 1500px;
  background: #11131C;
  color: white;
  overflow: auto;
}

.rewardImages {
  max-width: 100%;
  padding: 1px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 20px rgb(236, 236, 106);;
  box-shadow: 0 0 15px rgb(236, 236, 106);
}

.rewardTypeImage{
  max-width: 100%;
  max-height: 170px;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 800px) {
    max-height: 135px;
  }
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(6, 11, 40, 0.74);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #180437; 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c0437;
}

// .stoneCard{
//   background: linear-gradient(127.09deg, rgba(19, 2, 82, 0.94) 19.41%, rgba(16, 26, 77, 0.49) 76.65%) border-box;
// 	padding: 20px;
// 	border-radius: 25px;
// 	margin-top: 10px;
// 	align-content: center;
// 	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// 	min-height: 250px;
// 	backdrop-filter: blur(120px);
// }

.stone1Container {
  background: url('../../assets/img/crystal_raw.png') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 0 10px #e9eeee, 0 0 10px #e9eeee, 0 0 10px #e9eeee, 0 0 10px #e9eeee;
}

.stone2Container {
  background: url('../../assets/img/amethyst_raw.png') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 0 10px #7E57C2, 0 0 10px #7E57C2, 0 0 10px #7E57C2, 0 0 10px #7E57C2;
}

.stone3Container {
  background: url('../../assets/img/sapphire_raw.png') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 0 10px #1963F8, 0 0 10px #1963F8, 0 0 10px #1963F8, 0 0 10px #1963F8;
}

.stone4Container {
  background: url('../../assets/img/emerald_raw.png') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 0 10px #66BB6A, 0 0 10px #66BB6A, 0 0 10px #66BB6A, 0 0 10px #66BB6A;
}

.stoneCard {
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(21, 47, 68, 0.5);  
}

.stoneButton{
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
}
