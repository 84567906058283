@import "../../assets/sass/theme.scss";

.title {
  font-size: #{scaleXL(60)};
  font-family: $viet;
  font-weight: 600;
  text-align: center;
  margin: #{scaleXL(150)} 0 #{scaleXL(0)} 0;
}

.text {
  font-size: #{scaleXL(20)};
  font-family: $viet;
  font-weight: 400;
  color: #b1b3b9;
  text-align: center;
  line-height: #{scaleXL(32)};
  margin: 0 auto #{scaleXL(60)} auto;
  width: 100%;
  max-width: #{scaleXL(831)};
}

.faqs {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(20)};
  max-width: #{scaleXL(1095)};
  margin: 0 auto;
  margin-bottom: #{scaleXL(150)};
}

.faq {
  z-index: 7;
  border: #{scaleXL(1.5)} solid #222626;
  border-radius: #{scaleXL(8)};
  width: 100%;
  padding: #{scaleXL(24)} #{scaleXL(30)} #{scaleXL(24)} #{scaleXL(25)};

  @media (max-width: 800px) {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

.faq.show {
  background: #191438 !important;
  border-color: #2b293a !important;

  .description {
    height: fit-content !important;
  }
}

.faqTitle {
  font-size: #{scaleXL(20)};
  @include text500();
  color: #b1b3b9;
  line-height: #{scaleXL(33)};
}

.faqTitle.show {
  font-size: #{scaleXL(22)} !important;
  color: $white !important;
}

.description {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  padding-top: #{scaleXL(12)};
  display: flex;
  gap: #{scaleXL(16)};
  max-width: #{scaleXL(962)};

  font-size: #{scaleXL(16)};
  @include text400();
  color: #acaeb5;
  line-height: #{scaleXL(25.6)};
}