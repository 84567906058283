@import "../../assets/sass/theme.scss";

.headerBox {
    font-family: $viet;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: start;
    background: url('../../../public/media/Rectangle\ 91.svg'), lightgray -775.682px -750.993px / 151.658% 799.478% no-repeat;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0 #{scaleXL(40)} #{scaleXL(30)} #{scaleXL(30)};

    margin: #{scaleXL(50)} 24px;
    max-width: #{scaleXL(1630)};

    @media (max-width: 500px) {
        //
    }

    .h3 {
        font-family: $viet;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}

.headerBox2 {
  font-family: $viet;
  border-radius: 10px;
  border: none;
  color: white;
  margin: 0 24px;
  max-width: #{scaleXL(1630)};

  .h3 {
      font-family: $viet;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
  }
}

.headerText {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--white, #FFF);
    font-family: $viet;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    opacity: 0.8;
}

.crumbElement {
    font-weight: 400;
}

.buttonDark {
  text-decoration: none;
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, rgba(50, 63, 220, 0.20) 0%, rgba(179, 72, 233, 0.20) 100%);
  padding: #{scaleXL(5)} 0;
  border-radius: #{scaleXL(8)};

  @include text500();
  font-size: #{scaleXL(14)};
  color: $white;
  cursor: pointer;

  &:hover {
      filter: saturate(120%) contrast(1.5) drop-shadow(2px 2px 2px #8888888a);
      color: white;
  }  
}

.buttonLight {
  text-decoration: none;
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, #323fdc 0%, #b348e9 100%);
  padding: #{scaleXL(5)} 0;
  border-radius: #{scaleXL(8)};

  @include text500();
  font-size: #{scaleXL(14)};
  color: $white;
  cursor: pointer;

  &:hover {
      filter: saturate(120%) contrast(1.2) drop-shadow(2px 2px 2px #8888888a);
      color: white;
  }      
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
  color: gray !important;
  font-weight: bold !important;
  filter: saturate(60%) contrast(1.2);
}

.shatter {
  animation: shatter 0.5s ease-out; /* Define the shatter animation */
}
  
@keyframes shatter {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) rotate(15deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
}

.moveEffect {
  position: relative;
}
  
.rewardInfo {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: gray;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 40%;
  height: 20%;
  align-items: center;
}
  
.mysteryImage {
  max-width: 100%;
  border-radius: 20px;
}

.sectionContainer {
  background: #0C0E15;
  padding: 20px;
  border-radius: 10px;
}

.numbersModal {
  border-radius: 10px;
  width: 100%;
  max-width: 1500px;
  background: #11131C;
  color: white;
  overflow: auto;
}

.rewardImages {
  max-width: 100%;
  padding: 1px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 20px rgb(236, 236, 106);;
  box-shadow: 0 0 15px rgb(236, 236, 106);
}

.rewardTypeImage{
  max-width: 100%;
  max-height: 170px;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 800px) {
    max-height: 135px;
  }
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(6, 11, 40, 0.74);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #180437; 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c0437;
}

.responsiveImage {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  max-height: 70vh;
}

.timerTitle{
  color: #22b868;
  font-size: 20px;
  text-align: center;
  margin-bottom: -10px;
}

.timerCompletedTitle{
  font-size: 40px;
  font-weight: 500;
  color: #22b868;
  text-align: center;
}

.timerDigits{
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  padding: .5rem!important;

  @media (max-width: 500px) {
    font-size: 20px;
    padding: .2rem!important;
  }
}

.miniTimerDigits{
  font-size: 30px;
  font-weight: 500;
  color: #32CD32;
  white-space: nowrap;
}

.timerDelimiter{
  font-size: 26px;
  font-weight: 500;
  color: #444781;
  padding: 10px;
  margin-top: 7px;

  @media (max-width: 500px) {
    font-size: 16px;
    margin-top: 0;
    padding: 5px;
  }
}

.howToTitle{
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  margin-top: 50px;

  @media (max-width: 500px) {
    font-size: 28px;
  }
}

// how to cards
.bgImg {
  background-size: cover;
  background-position: 50%;
}

.playCard {
  padding: 30px 25px;
  border-radius: 10px;
  transition: all .3s;
}

.playCardOne {
  background-image: url('./../../assets/img/card_1.png');
}

.playCardTwo {
  background-image: url('./../../assets/img/card_2.png');
}

.playCardThree {
  background-image: url('./../../assets/img/card_3.png');
}

.playCard:hover {
  transform: scale(1.05);
}

.playCard:hover .playCardIcon:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.playCardIcon {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  margin: 0 auto;
  box-shadow: 0 10px 10px 0 rgba(5, 119, 219, 0.6),inset 0 0 15px 5px #3db4e4;
  border-radius: 50%;
  z-index: 1;
}

.playCardIcon:after {
  content: "";
  top: -15px;
  left: -15px;
  width: 130px;
  height: 130px;
  border: 1px solid hsla(0, 0%, 100%, 0.459);
  z-index: -1;
  transition: all .3s;
}

.playCardIcon .playCardNumber,.playCardIcon:after {
  position: absolute;
  border-radius: 50%;
}

.playCardIcon .playCardNumber {
  bottom: -6px;
  right: -21px;
  width: 40px;
  height: 40px;
  background-image: -ms-linear-gradient(-45deg,#c165dd 0,#5c27fe 100%);
  text-align: center;
  line-height: 44px;
  font-size: 22px;
  color: #fff;
}

.playCardContent {
  text-align: center;
  margin-top: 50px;
}

.playCardContent p {
  margin-top: 10px;
}

.playCardTitle {
  text-transform: uppercase;
}

.raffleInfoBox {
  background-color: #0C0E15;
  padding: 20px;
  border-radius: 10px;
  min-height: 825px;

  @media (max-width: 500px) {
    min-height: auto;
  }
}

.raffleBuyBox {
  background-color: #130f57;
  padding: 20px;
  border-radius: 10px;
  min-height: 825px;

  @media (max-width: 500px) {
    min-height: auto;
  }
}

.raffleBuyBoxImage {
  background-image: url('./../../assets/img/card_2.png');  
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.paymentInfo{
  width: 100%;
  max-height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.buyContainer {
  display: flex;
  align-items: center;
  border: 1px solid rgb(68, 71, 129);
  border-radius: 25px;
  padding: 10px;
  width: 55%;
  justify-content: center;
}

.buyButton {
  background-color: rgb(68, 71, 129);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
}

.buyValueDisplay {
  flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.prizeImage{
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 8px #5C6BC0, 0 0 8px #5C6BC0, 0 0 8px #5C6BC0, 0 0 8px #5C6BC0;
}

.historyCard{
  background-color: #191919;
  padding: 20px;
  border-radius: 10px;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.prizesTitle{
  font-size: calc(1.325rem + .9vw);

  @media (max-width: 500px) {
    font-size: 18px;
  }
}