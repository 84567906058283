@import "../../assets/sass/theme.scss";

.headerBox {
  font-family: $viet;
  border-radius: 10px;
  border: none;
  color: white;
  background: url('../../../public/media/Rectangle\ 91.svg'), lightgray -775.682px -750.993px / 151.658% 799.478% no-repeat;
  padding: 20px;

  .h3 {
      font-family: $viet;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
  }
}

.headerBox2 {
  font-family: $viet;
  border-radius: 10px;
  border: none;
  color: white;

  .h3 {
      font-family: $viet;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
  }
}

.headerText {
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: var(--white, #FFF);
  font-family: $viet;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  opacity: 0.8;
}

.variantCard {
  background: #0C0E15;
  min-height: 100px;
  border-radius: 10px;
  padding: 15px;
}

.numbersModal {
  // border-radius: 20px;
  width: 100%;
  max-width: 1500px;
  background: #11131C;
  color: white;
  overflow: auto;

}