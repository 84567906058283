@import "../../assets/sass/theme.scss";

.UserDetailsComponent {
  @include flexCenter();
  padding: 0 #{scaleXL(40)};
  gap: #{scaleXL(80)};
  max-width: #{scaleXL(1519)};
  margin: #{scaleXL(150)} auto;

  @media (max-width: 1000px) {
    align-items: center;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
  color: gray !important;
  font-weight: bold !important;
  filter: saturate(60%) contrast(1.2);
}

.userImg {
  width: #{scaleXL(695)};
  height: #{scaleXL(744)};
  border-radius: #{scaleXL(30)};
}

.aside {
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(60)};
}

.title {
  @include text600();
  font-size: #{scaleXL(42)};
  // text-align: center;
}

.text {
  @include text400();
  font-size: #{scaleXL(18)};
  color: #b1b3b9;
  line-height: #{scaleXL(32)};
  max-width: #{scaleXL(653)};
}

.impressions {
  @include flexCenter();
  gap: #{scaleXL(20)};
  margin-bottom: #{scaleXL(20)};
}

.impression {
  @include flexCenter();
  gap: #{scaleXL(10)};
  background: #11111e;
  padding: #{scaleXL(8)} #{scaleXL(16)};
  border-radius: #{scaleXL(20)};
  border: 1px solid #1a1629;

  @include text400();
  font-size: #{scaleXL(16)};
}

.details {
  @include flexCenterBetween();
  height: #{scaleXL(296)};
  width: 100%;
  gap: #{scaleXL(30)};
  background: #101525;
  padding: #{scaleXL(30)};
  border-radius: #{scaleXL(20)};
}

.account {
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(60)};
  width: #{scaleXL(291)};
}

.balance {
  @include flexCenter();
  height: #{scaleXL(57)};
  width: 100%;
  background: #161c30;
  padding: #{scaleXL(14)} #{scaleXL(24)};
  border-radius: #{scaleXL(8)};
  border: 1px solid #1e2332;
}

.balance {
  @include text400();
  font-size: #{scaleXL(16)};
  color: #b1b3b9;
}

.balanceText {
  @include text400();
  font-size: #{scaleXL(16)};
  color: #b1b3b9;
  margin-right: 5px;
}

.balanceNum {
  @include text500();
  font-size: #{scaleXL(18)};
  color: $white;
}

.bids {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(19)};
}

.bid {
  @include flexCenterBetween();
}

.barImg {
  height: #{scaleXL(236)};
  width: 1px;
  border-right: 1px dashed #14504e;
  // background-color: #B1B3B9;
}

.dates {
  display: flex;
  gap: #{scaleXL(16)};
  margin-top: #{scaleXL(24)};
  margin-bottom: #{scaleXL(60)};
}

.date {
  @include flexCenter();
  flex-direction: column;
  width: fit-content;
  background: #161c30;
  padding: #{scaleXL(4)} #{scaleXL(10)};
  border-radius: #{scaleXL(8)};
  border: 1px solid #1e2332;
}

.dateTitle {
  @include text600();
  font-size: #{scaleXL(22)};
  color: #28dfd9;
}

.dateText {
  @include text400();
  font-size: #{scaleXL(18)};
  color: #b1b3b9;
}

.bidBtn {
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, #323fdc 0%, #b348e9 100%);
  padding: #{scaleXL(14)} 0;
  border-radius: #{scaleXL(8)};

  @include text500();
  font-size: #{scaleXL(18)};
  color: $white;
  cursor: pointer;

  &:hover {
    filter: saturate(120%) contrast(1.2) drop-shadow(2px 2px 2px #8888888a);
    color: white;
  }
}

.bidBtnTwo {
  @include flexCenter();
  justify-content: center;
  width: 100%;
  background: linear-gradient(134deg, #323fdc 0%, #b348e9 100%);
  // padding: #{scaleXL(14)} 0;
  border-radius: #{scaleXL(8)};
  padding-left: 50px;
  // padding-right: -100px;

  @include text500();
  font-size: #{scaleXL(18)};
  color: $white;
  cursor: pointer;

  &:hover {
    filter: saturate(120%) contrast(1.2) drop-shadow(2px 2px 2px #8888888a);
    color: white;
  }
}

.bided {
  @include flexCenter();
  justify-content: center;
  height: 100%;
  width: #{scaleXL(320)};

  @include text600();
  font-size: #{scaleXL(32)};
  color: $white;
}

// RelatedProductsComponent
.RelatedProductsComponent {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(60)};
  width: 100%;
  padding: #{scaleXL(40)};
  padding-bottom: #{scaleXL(150)};
  max-width: #{scaleXL(1519)};
  margin: 0 auto;
}

.products {
  display: flex;
  // gap: #{scaleXL(30)};
  padding: 0px;
}

.header {
  @include flexCenter();
  gap: #{scaleXL(20)};

  @include text600();
  font-size: #{scaleXL(42)};
  color: $white;
}

.timerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100% !important;

}

.product {
  @include BgImgContain();
  background-size: #{scaleXL(400)},
  #{scaleXL(358)} !important;
  width: #{scaleXL(308)};
  height: #{scaleXL(409)};
  display: flex;
  flex-direction: column;
  border: 1px solid #5724c3;
  overflow: hidden;
  border-radius: #{scaleXL(14.7)};
}

.productTime {
  @include text400();
  font-size: #{scaleXL(16)};
  color: #b1b3b9;
  margin-left: auto;
  background: $deamBlue;
  padding: #{scaleXL(12)};
  border-radius: 0 0 0 #{scaleXL(20)};
}

.productName {
  @include text400();
  font-size: #{scaleXL(12)};
  color: #b1b3b9;
  margin-top: auto;
  padding: 0 #{scaleXL(13)};
}

.productPrice {
  @include text600();
  font-size: #{scaleXL(22)};
  color: $white;
  padding: 0 0 #{scaleXL(13)} #{scaleXL(13)};
}

.ul {
  gap: 15px;
}

.border {
  --a: .1em;
  --b: .5em;
  /* border width */
  --c: 3em;
  /* corner size */
  --r: 2em;
  /* corner rounding */
  position: relative;
  //  margin: 1em auto; 
  border: solid var(--b) transparent;
  //  padding: 0.5em; 
  // max-width: 23em;
  // padding-bottom: 10px !important;
  // padding-top: 10px !important;
  
  

  &::before {
    position: absolute;
    z-index: -1;
    inset: calc(-1*var(--b));
    border: inherit;
    border-radius: var(--r);
    background: linear-gradient(#211534, $deamBlue, #211534) border-box; //211534   b348e9
    --corner:
      conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0) 0 0/ calc(100% - var(--c)) calc(100% - var(--c)) border-box;
    --inner: conic-gradient(red 0 0) padding-box;
    -webkit-mask: var(--corner), var(--inner);
    -webkit-mask-composite: source-out;
    mask: var(--corner) subtract, var(--inner);
    content: '';
  }

}



@media (max-width: 768px) {
  .UserDetailsComponent {
    display: flex;
    flex-direction: column;
  }

  .userImg {
    width: 100%;
    height: #{scaleXL(530)};
    border-radius: #{scaleXL(30)};
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: #{scaleXL(60)};
  }

  .text {
    max-width: 100%;
  }

  .details {
    flex-direction: column;
    height: #{scaleXL(696)};
    max-width: #{scaleXL(530)};
    margin: 0 auto;
    gap: #{scaleXL(30)};
  }

  .barImg {
    width: 100%;
    height: 1px;
    border-right: none;
    border-bottom: 1px dashed #14504e;
  }

  // RelatedProductsComponent
  .product {
    @include BgImgContain();
    background-size: #{scaleXL(400)},
    #{scaleXL(358)} !important;
    width: #{scaleXL(358)};
    height: #{scaleXL(409)};
    display: flex;
    flex-direction: column;
    border: 1px solid #5724c3;
    overflow: hidden;
    border-radius: #{scaleXL(14.7)};
  }

  .header {
    @include flexCenter();
    gap: #{scaleXL(20)};

    @include text600();
    font-size: #{scaleXL(32)};
    color: $white;

    img {
      height: #{scaleXL(50)};
      width: #{scaleXL(50)};
    }
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .UserDetailsComponent {
    // @include flexCenter();
    display: grid;
    grid-template-columns: 30% 60%;
    padding: 0 #{scaleXL(40)};
    width: 100%;
    gap: #{scaleXL(80)};
    margin: #{scaleXL(150)} auto;
  }

  .userImg {
    width: 100%;
    height: auto;
    border-radius: #{scaleXL(30)};
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: #{scaleXL(60)};
  }

  // RelatedProductsComponent
  .product {
    @include BgImgContain();
    background-size: #{scaleXL(400)},
    #{scaleXL(358)} !important;
    width: #{scaleXL(358)};
    height: #{scaleXL(409)};
    display: flex;
    flex-direction: column;
    border: 1px solid #5724c3;
    overflow: hidden;
    border-radius: #{scaleXL(14.7)};
  }
}